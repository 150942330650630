html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: var(--font-family-body);
  background-color: var(--color-body-bg);
  color: var(--color-body-text);
}
